import type { TitanTheme } from '@elseu/sdu-titan';
import { isSSR } from 'helpers/isSSR';
import { useEffect } from 'react';
import { useTheme } from 'styled-components';

export const GreyBackground = () => {
  const theme = useTheme() as TitanTheme;

  useEffect(() => {
    if (isSSR) return;
    document.body.style.backgroundColor = theme.designTokens.colors.grey10;

    return () => {
      document.body.style.backgroundColor = 'inherit';
    };
  });

  return null;
};
