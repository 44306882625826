import { useMutation } from '@apollo/client';
import { MutationType } from '@elseu/sdu-evidend-graphql';
import {
  Button,
  List,
  ListItem,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Text,
} from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { CREATE_MUTATION } from 'graphql/queries/mutation';
import { formatMutationTypeLink } from 'helpers/formatMutation';
import { useRouter } from 'next/router';
import React from 'react';
import type { CreateMutation, CreateMutationVariables } from 'types/graphql/CreateMutation';
import { incorporationSteps } from 'wizards/Incorporation';

interface IIncorporationStartModal {
  isModalShown: boolean;
  onModalClose: () => any;
  legalEntityId?: string;
}

export const IncorporationStartModal = ({
  isModalShown,
  onModalClose,
  legalEntityId,
}: IIncorporationStartModal) => {
  const router = useRouter();
  const [createMutation] = useMutation<CreateMutation, CreateMutationVariables>(CREATE_MUTATION);

  const createLegalEntity = async (mutationType: MutationType) => {
    if (legalEntityId) {
      createMutation({
        variables: {
          legalEntityId,
          mutationType,
        },
        onCompleted: ({ createMutation }) => {
          router.push({
            pathname: `/${formatMutationTypeLink(mutationType)}/${createMutation.id}`,
          });
        },
        onError: (error) => {
          console.error(error);
        },
      });
    } else {
      router.push({
        pathname: `/${formatMutationTypeLink(mutationType)}/aanmaken/${
          incorporationSteps.LegalEntityCreateSelect
        }`,
      });
    }
  };

  return (
    <Modal isShown={isModalShown} size="m" onClose={onModalClose}>
      <ModalHeader title={t`Register aanmaken`} />
      <ModalContent>
        <Text color="grey80" spaceAfter={4} type="paragraph">
          <Trans>In Evidend kun je op twee manieren een register aanmaken:</Trans>
        </Text>
        <List spaceAfter={4} type="ol">
          <ListItem prefixAdornment="•">
            <Trans>alle mutaties opvoeren vanaf oprichtingsdatum</Trans>
          </ListItem>
          <ListItem prefixAdornment="•">
            <Trans>
              de verdeling van aandelen op een bepaalde datum en eventuele mutaties vanaf die datum
              opvoeren
            </Trans>
          </ListItem>
        </List>
        <Text color="grey80" type="paragraph">
          <Trans>Wat wil je gebruiken als startpunt?</Trans>
        </Text>
      </ModalContent>
      <ModalActions>
        <Button variant="clear" onClick={() => createLegalEntity(MutationType.ONBOARDING)}>
          <Trans>Andere datum</Trans>
        </Button>
        <Button variant="primary" onClick={() => createLegalEntity(MutationType.INCORPORATION)}>
          <Trans>Oprichtingsdatum</Trans>
        </Button>
      </ModalActions>
    </Modal>
  );
};
