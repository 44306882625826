import type { Register } from '@elseu/sdu-evidend-graphql';
import { CheckboxIcon, InformationIcon, Tag } from '@elseu/sdu-titan';
import type { StatusColors } from '@elseu/sdu-titan/dist/types/types/status-colors';
import { Trans } from '@lingui/macro';

type RegisterSignatureTagProps = Pick<Register, 'isSigned' | 'signatures'>;
export const getSignatureInfo = ({
  isSigned,
  signatures,
}: RegisterSignatureTagProps): {
  status: StatusColors;
  label: React.ReactNode;
  icon: React.ReactNode;
} => {
  switch (isSigned) {
    case false: {
      if (signatures.length)
        return {
          status: 'warning',
          label: <Trans>Gewijzigd</Trans>,
          icon: <InformationIcon />,
        };
      return {
        status: 'error',
        label: <Trans>Niet ondertekend</Trans>,
        icon: <InformationIcon />,
      };
    }
    default:
      return {
        status: 'success',
        label: <Trans>Ondertekend</Trans>,
        icon: <CheckboxIcon />,
      };
  }
};

export const RegisterSignatureTag = ({ isSigned, signatures }: RegisterSignatureTagProps) => {
  const { status, label, icon } = getSignatureInfo({ isSigned, signatures });

  return (
    <Tag prefixAdornment={icon} status={status}>
      {label}
    </Tag>
  );
};
