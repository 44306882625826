import { useQuery } from '@apollo/client';
import type { MutationFilterInput, Register } from '@elseu/sdu-evidend-graphql';
import { AccountEntitlement, PartySearchType, SortOrder } from '@elseu/sdu-evidend-graphql';
import {
  Box,
  Card,
  ContentWrapper,
  DrawerList,
  DrawerListItem,
  EmptyState,
  Flex,
  Heading,
  Link,
  Loader,
  PlusIcon,
  useGreaterThan,
} from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { GreyBackground } from 'components/GreyBackground/GreyBackground';
import { IncorporationStartModal } from 'components/IncorporationStartModal/IncorporationStartModal';
import { RegisterSignatureTag } from 'components/register/RegisterSignature/RegisterSignatureTag';
import { SidebarNavigation } from 'components/SidebarNavigation/SidebarNavigation';
import { MUTATIONS_QUERY } from 'graphql/queries/mutation';
import { PARTY_DASHBOARD_QUERY } from 'graphql/queries/party';
import { formatMutationType, formatMutationTypeLink } from 'helpers/formatMutation';
import { formatDateFromNow } from 'helpers/formatShares';
import { PageTitle } from 'helpers/PageTitle';
import { useAccount, useEntitlements } from 'hooks/useAccount';
import { orderBy } from 'lodash';
import type { NextPage } from 'next';
import { useState } from 'react';
import styled from 'styled-components';
import type { Mutations, MutationsVariables } from 'types/graphql/Mutations';
import type {
  PartyDashboardQuery,
  PartyDashboardQueryVariables,
} from 'types/graphql/PartyDashboardQuery';

const StyledDrawerList = styled(DrawerList)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const StyledDrawerListItem = styled(DrawerListItem)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const DashboardItem = ({ children, action, title }: any) => (
  <Card spaceAfter={8}>
    <Box py={2}>
      <Flex justifyContent="space-between" spaceAfter={4}>
        <Heading heading="h4">{title}</Heading>
        {action && <Flex alignItems="center">{action}</Flex>}
      </Flex>
      {children}
    </Box>
  </Card>
);

const MyRegisters = () => {
  const [, canCreateRegister] = useEntitlements([AccountEntitlement.REGISTER_CREATE]);
  const [isIncorporationModalShown, setIncorporationModalShown] = useState<boolean>(false);
  const { data: { partySearch } = {} } = useQuery<
    PartyDashboardQuery,
    PartyDashboardQueryVariables
  >(PARTY_DASHBOARD_QUERY, {
    variables: {
      pagination: {
        page: 0,
        size: 6,
        sort: [{ field: 'recentlyViewed', order: SortOrder.DESC }],
      },
      filter: {
        type: PartySearchType.ACCESSIBLE_REGISTERS,
      },
    },
  });

  if (partySearch) {
    if (partySearch.items.length === 0) {
      return (
        <DashboardItem title={t`Mijn registers`}>
          <Flex justifyContent="center">
            <Box py={20}>
              <EmptyState
                isCentered
                description={
                  canCreateRegister ? (
                    <Trans>
                      Er zijn geen registers gevonden waar je toegang toe hebt. Registreer een
                      oprichting binnen Evidend door met onderstaande knop een register aan te
                      maken.
                    </Trans>
                  ) : (
                    <Trans>
                      Er zijn geen registers met je gedeeld. Zodra een register met je gedeeld is,
                      kun je het hier terugvinden.
                    </Trans>
                  )
                }
                link={
                  canCreateRegister
                    ? {
                        onClick: () => setIncorporationModalShown(true),
                        label: t`Register aanmaken`,
                      }
                    : undefined
                }
                title={
                  canCreateRegister ? (
                    <Trans>Maak een eerste register aan</Trans>
                  ) : (
                    <Trans>Geen registers beschikbaar</Trans>
                  )
                }
              />
            </Box>
          </Flex>

          <IncorporationStartModal
            isModalShown={isIncorporationModalShown}
            onModalClose={() => setIncorporationModalShown(false)}
          />
        </DashboardItem>
      );
    }

    return (
      <DashboardItem
        action={
          canCreateRegister ? (
            <Link prefixAdornment={<PlusIcon />} onClick={() => setIncorporationModalShown(true)}>
              <Trans>Register aanmaken</Trans>
            </Link>
          ) : null
        }
        title={t`Mijn registers`}
      >
        <StyledDrawerList spaceAfter={6}>
          {partySearch.items.map((result) => {
            if (!result.legalEntity) return null;
            const { id, countryOfRegistration, name, tradeRegisterNumber } = result.legalEntity;
            const isCountryNL = countryOfRegistration === 'NL';

            return (
              <StyledDrawerListItem
                key={id}
                description={
                  <Flex justifyContent="space-between">
                    {isCountryNL ? t`KVK: ${tradeRegisterNumber}` : tradeRegisterNumber || ''}
                    {result.legalEntity.register && (
                      <RegisterSignatureTag {...(result.legalEntity.register as Register)} />
                    )}
                  </Flex>
                }
                linkTo={`/rechtspersoon/${id}`}
                title={name}
              />
            );
          })}
        </StyledDrawerList>

        <Link to="/registers">
          <Trans>Bekijk al mijn registers ({partySearch.pagination.totalCount})</Trans>
        </Link>

        <IncorporationStartModal
          isModalShown={isIncorporationModalShown}
          onModalClose={() => setIncorporationModalShown(false)}
        />
      </DashboardItem>
    );
  }

  return (
    <DashboardItem title={t`Mijn registers`}>
      <Box py={20}>
        <Flex alignItems="center" justifyContent="center">
          <Loader height={80} variant="spinner" width="80" />
        </Flex>
      </Box>
    </DashboardItem>
  );
};

const MyWorkflows = () => {
  const account = useAccount();

  const { data: { mutations } = {} } = useQuery<Mutations, MutationsVariables>(MUTATIONS_QUERY, {
    variables: {
      filter: {
        isDraft: true,
      } as MutationFilterInput,
      pagination: {
        page: 0,
        size: 10000,
        sort: [],
      },
    },
  });

  if (!mutations)
    return (
      <DashboardItem title={t`Mijn workflows`}>
        <Box py={20}>
          <Flex alignItems="center" justifyContent="center">
            <Loader height={80} variant="spinner" width="80" />
          </Flex>
        </Box>
      </DashboardItem>
    );

  const totalCount = mutations.items.length;
  const filteredMutations = mutations.items.filter(
    (mutation) => mutation.lastUpdatedBy.id === account?.user.id,
  );
  const items = orderBy(filteredMutations, 'lastUpdatedOn', 'desc');

  if (items.length === 0) {
    return (
      <DashboardItem title={t`Mijn workflows`}>
        <Flex justifyContent="center">
          <Box py={20}>
            <EmptyState
              isCentered
              description={t`Een workflow voor een mutatie kan gestart worden vanuit een register.`}
              title={t`Je hebt geen actieve workflows`}
            />
          </Box>
        </Flex>
      </DashboardItem>
    );
  }

  return (
    <DashboardItem title={t`Mijn workflows`}>
      <StyledDrawerList spaceAfter={6}>
        {items.map((mutation) => {
          const { id, type, lastUpdatedOn, register } = mutation;
          const mutationTypeLabel = formatMutationType(mutation);
          const mutationTypeLink = formatMutationTypeLink(type);
          const titleName = register.legalEntity.name || t`Onbekend`;
          return (
            <StyledDrawerListItem
              key={id}
              description={t`Laatst bijgewerkt: ${formatDateFromNow(lastUpdatedOn)}`}
              linkTo={`${mutationTypeLink}/${id}`}
              title={`${mutationTypeLabel}: ${titleName}`}
            />
          );
        })}
      </StyledDrawerList>

      <Link to="/mutaties/overzicht">
        <Trans>Bekijk alle workflows ({totalCount})</Trans>
      </Link>
    </DashboardItem>
  );
};

export const Home: NextPage = () => {
  const isLarge = useGreaterThan('s');

  return (
    <>
      <PageTitle title={t`Home`} />
      <SidebarNavigation />

      <GreyBackground />

      <ContentWrapper maxWidth={1040} spaceAfter={8}>
        <Box pt={20}>
          <Heading heading="h2" renderAs="h1" spaceAfter={6}>
            <Trans>Home</Trans>
          </Heading>
        </Box>

        <Columns count={isLarge ? 2 : 1} gap={8}>
          <Column>
            <MyRegisters />
          </Column>

          <Column>
            <MyWorkflows />
          </Column>
        </Columns>
      </ContentWrapper>
    </>
  );
};

export default Home;
